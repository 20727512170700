import * as React from 'react';
import type { GatsbyBrowser } from 'gatsby';

import Layout from './src/components/layout/Layout';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
}) => {
  return <Layout>{element}</Layout>;
};

export const onInitialClientRender = () => {
  document.body.classList.remove('loading');
};
